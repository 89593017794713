body {
  background-color: #F5F5F5;
  padding-top: 70px;
}

label, input, select {
  font-size: 14px;
}

.btn-primary{
  background-color: #3bafda !important;
  border-color: #3bafda  !important;
}

.bg-light {
  background: #3bafda !important;
}

a {
  color: #3bafda;
  cursor: pointer;
  &:hover {
    color: #3bafda;
  }
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
  background-color: #3bafda;
}

// .custom-control-label::after, .custom-control-label::before{
//   width: 1.3rem;
//   height: 1.3rem;
// }

h5 {
  font-size: 18px;
}

.table thead th {
  border-bottom: none !important;
  color: #726C66;
}

.table tr td {
  a{
    em{
        color: #3bafda;
        
        &.md-create {
          top: -2px;
        }
        &.fa-trash {
          top: -4px;
        }
    }
  }
}

.nav-tabs .nav-link{
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-bottom: 5px solid #3bafda !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-bottom: 5px solid #3bafda !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}

.nav>li>a:focus,
.nav>li>a:hover {
  text-decoration: none;
  background-color: transparent !important;
}

.table td,
.table th {
  padding: .15rem;
  font-size: 14px;
}

.table td {
  em {
    top: -6px;
    position: relative;
  }

}

.add-connectionlist-tbl td em{
  top: 0px;
  position: unset;
}

ul {
  padding-left: 0px;

  li {
    list-style-type: none;
  }
}

.navbar {
  height: 50px;

  li {

    height: 50px;
    a {
      color: #fff;
      line-height: 50px !important;
    }

    em {
      color: #fff !important;
      font-size: 20px;
    }

    span.name {
      padding-top: 20px;
    }

    a p {
      margin-bottom: 0px !important;
      padding-bottom: 0px !important;
    }
  }

  .dropdown-menu {
    top: 71% !important;
    left: -122px !important;

    li {
      a {
        line-height: unset !important;
        padding-left: 12px;
        font-size: 12px;
      }
    }
  }
}

header {
  margin-bottom: 30px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

.card-header {
  background-color: transparent !important;
  border-bottom: none !important;
  padding-bottom: 0px;
}

.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show>.nav-link {
  color: #fff !important;
}

.navbar-light .navbar-nav .nav-link {
  color: #fff !important;
  cursor: pointer;
  padding: 0px 10px;
}

.wrapper-page .form-control-feedback {
  left: 11px;
  top: -35px !important;
  color: rgba(76, 86, 103, 0.4);
  font-size: 20px;
  position: relative;
}

.btn-circle.btn-lg {
  min-width: 40px;
  width: 40px;
  height: 40px;
  padding: 5px 10px;
  font-size: 18px;
  line-height: 1.33;
  border-radius: 25px;
  box-shadow: 1px 1px 9px 1px rgba(0, 0, 0, 0.21) !important;
}

.btn-info {
  background-color: #3bafda !important;
  border: 1px solid #3bafda !important;
}

.col-form-label {
  font-size: 12px;
  color: #726C66;
  display: block;
}

.modal-header {
  background: #3bafda !important;
  padding: 0.5rem 1rem;

  h5 {
    font-size: 14px !important;
    color: #fff;
  }

}

.modal-btn-group {
  display: inline-block !important;
}

.modal-footer {
  border: none !important;
  text-align: center;
  display: inherit !important;
}

.form-control:focus,
.form-control:hover {
  box-shadow: none !important;
  outline: none !important;
  border-color: #ced4da;
}

.card {
  border-radius: 0.25rem;
  box-shadow: 0 2px 10px -1px rgba(69, 90, 100, 0.3);
  border: none;
  margin-bottom: 30px;
  transition: all 0.3s ease-in-out;
}

.show-controls {
  .show-btn-group {
    visibility: hidden;
  }
}

.show-controls:hover {
  background: #c7e8f56b !important;

  .show-btn-group {
    visibility: visible;
  }
}

.switch {
  transition: unset !important;
  -webkit-transition: unset !important;
}

.switch small {
  transition: unset !important;
  -webkit-transition: unset !important;
}

.error-msg,
.md-content .error-msg {
  color: red !important;
  font-size: 12px !important;
}

.navbar .nav-item:hover {
  background-color: #82c3dbc9;
  color: #fff !important;
  outline: unset !important;

  a {
    color: #fff !important;
    outline: unset !important;
  }

  a:hover {
    color: #fff !important;
    outline: unset !important;
  }
}

.nav-item.active {
  background-color: #82c3dbc9;
  color: #fff !important;
  outline: unset !important;

  a {
    color: #fff !important;
    outline: unset !important;
  }

  a.active {
    color: #fff !important;
    outline: unset !important;
  }

}

.error {
  color: red;
}

/****** Set Fixed scroller ******/
 .scroll-window-wrapper {
    position: relative;
  }

  .scroll-window table {
    margin-bottom: 0;
  }

  .scroll-window:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 25px;
    display: table;
    z-index: 9;
    background: #fff;
  }

  table.fixed-header th div {
    position: absolute;
    margin-top: -21px;
    z-index: 11;
    color: #726C66;
  }
  table.fixed-header thead tr {
    height: 1.5rem;
  }

  table.fixed-header thead th {
    border-bottom: 1px solid #ddd;
  }

  // table.fixed-header tbody td {
  //   border-top: 0;
  //   padding: 5px 9px;
  // }

  #bodywrap ::-webkit-scrollbar {
    width: 5px;
  }

  .table-striped tbody tr:nth-of-type(odd) {
    background: none;
  }

  /****************************************/

.navbar li.language-selector a ~ ul li a{
    color: #000  !important;
  }

  ::-webkit-scrollbar-track {
    background-color: #ddd;
  }

  ::-webkit-scrollbar {
    width: 5px;
    background-color: #ddd;
    height: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #34bdf3;
  }


  .connections-type-tblsearch .drop-down {
    border: 1px solid #ddd !important;
    max-height: 200px !important;
    overflow: scroll !important;
    background-color: #fff !important;
    min-height: 200px;
    z-index: 99 !important;
    max-width: 368px;
    width: 368px;
  }

  .search-results.article-css.connections-type-tblcss {
    width: 360px !important;
    white-space: nowrap;
    padding: 0px 4px;
   &:hover{
      background: #3bafda !important;
      color: #fff;
    }

    a {
      font-size: 12px;
    }
  }

  .show-controls.active{
    background: #c7e8f56b !important;
  }

.remove-copy.col-md-10{
    max-width: 100% !important;
    flex: 100% !important;
}

.table-cursor-pointer{
  cursor: pointer !important;
}

.table tr.deactive-record td{
  color: rgb(185, 183, 183) !important;
}

.search-results {
  padding: 3px 10px !important;
  font-size: 13px;
  &:hover{
    background: #3bafda !important;
    color: #fff;
  }
}

.search-results.search-results-select {
  background: #3bafda !important;
  color: #fff;
  &:hover{
    background: #3bafda !important;
  }
}

.switch.switch-small {
  height: 13px;
  border-radius: 13px;
  min-width: 25px;
}

.switch.switch-small small {
  width: 13px;
  height: 13px;
  right: calc(100% - 13px);
}

.switch.checked small {
  right: 0;
  left: auto;
}

.search-criteria{
  border: 1px dashed #ddd;
  border-radius: 5px;
  padding: 10px;
}

.mt-18{
  margin-top: 18px;
}

.clients-list-tbl.table td em, .center-list-tbl.table td em, .clinic-list-tble.table td em {
  top: -2px;
  position: relative;
}

.page-item .page-link {
  color: #3bafda;
  padding: .2rem .5rem;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #3bafda !important;
  border-color: #3bafda !important;

}

.clients-list-tbl
{

tbody{

  tr{

    td{
      

&:last-child{
  width: 10%;
}

    }

  }

}

}


.badge-danger {
  max-width: 60px;
}

.badge-success {
  max-width: 60px;
}

.pagination {
  float: right;
}

table {

  tr {
    
    th {
      border-top: none !important;
    }
  }
}