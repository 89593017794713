
@import "./assets/css/bootstrap.min.css";
@import "./assets/css/icons.css";

@import "./assets/plugins/footable/css/footable.core.css";
@import "~sweetalert2/dist/sweetalert2.min.css";
@import '~ngx-ui-switch/ui-switch.component.scss';

.ng2-opd-popup-content-main{
    position:absolute;
    top:60vh;
}
.btn-danger{
    background-color: #ef5350 !important;
    border: 1px solid #ef5350 !important;
    margin-right: 40px !important;
}

.input-group-addon {
    padding: -1px 12px;
  }


  @media screen and (max-width: 1599px) and (min-width: 1366px) {
      
   #table-clients.table{
    td, th
    {
         font-size:0.8rem;
     }
   }

  #table-clinics.table td, #table-labs.table td, #table-labs.table th,
   .table.users-list-tble td,
   table users-list-tble th
   {
        font-size:0.8rem;
    }

}

#table-clients,#table-clinics, #table-labs, .table.users-list-tble {

    .switch{
        position: relative;
        top: 2px;
    }

    .show-btn-group{

        a{
            position: relative;
            top: 2px;

            &:last-child{
                top: 2px;
            }

        }
        

    }

}

.Centers-List{


    table{

        .show-btn-group{

            position: relative;
            top: 2px;
            
        }


    }

}

.card{

    .tab-content{

        .tab-pane{

            #txt-search{
                margin-top: 14px !important;
            }

        }

    }

}

#table-labs{
    .badge.badge-danger{
        padding-bottom: 3px;
        font-size: 70%;
    }
    .show-btn-group{

        a{
            top: 8px;

            &:last-child{

                top: 6px !important;           

            }

        }

    }

}

.users-list-tble{
    tbody{

        tr{

            td{
                vertical-align: middle;

                p{
                    margin-top: 5px;
                    margin-bottom: 5px;
                }

            }

        }

    }

}
.btn {
    &.btn-sm {
        &.btn-square-inline-field {
            em {
                padding: 0 1px;
            }
        }
    }
}

.auxiliary-list-table {

    th,
    td {
        font-size: 0.8rem !important;

        &:last-child {
            min-width: 100px;
        }
    }
}